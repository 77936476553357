import '../styles/about.scss'

import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import Link from 'gatsby-link'
import React from 'react'

import IconBlock from '../components/IconBlock'
import ImageHero from '../components/ImageHero'
import InlineVideo from '../components/InlineVideo'
import SEO from '../components/SEO'
import INWLogo from '../images/inw_logo_color.inline.svg'
import Layout from '../layouts/Layout'
import linkShapes from '../utils/linkShapes'

const AboutPage = () => {
  const data = useStaticQuery(graphql`
    query {
      datoCmsAboutPage {
        heroHeadline
        heroBody
        heroBodyNode {
          childMarkdownRemark {
            html
          }
        }
        ourMissionNode {
          childMarkdownRemark {
            html
          }
        }
        ourVisionNode {
          childMarkdownRemark {
            html
          }
        }
        commitmentHeadline
        commitmentBodyNode {
          childMarkdownRemark {
            html
          }
        }
        commitmentEsg {
          title
          descriptionNode {
            childMarkdownRemark {
              html
            }
          }
          icon {
            url
          }
        }
        timelineHeadline
        timelineBodyNode {
          childMarkdownRemark {
            html
          }
        }
        timelineEntries {
          id
          site {
            siteName
            logoLine1
            logoLine2
          }
          descriptionNode {
            childMarkdownRemark {
              html
            }
          }
          year
          video {
            url
          }
        }
        ourPeopleHeadline
        ourPeopleBodyNode {
          childMarkdownRemark {
            html
          }
        }
        ceoLetterHeadline
        ceoLetterBodyNode {
          childMarkdownRemark {
            html
          }
        }
        ceoLetterSignatureNode {
          childMarkdownRemark {
            html
          }
        }
        ceoPhoto {
          fluid(maxWidth: 480) {
            ...GatsbyDatoCmsFluid
          }
        }
      }

      hero: file(relativePath: { eq: "vanilla-shake-hero.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const dato = data.datoCmsAboutPage
  const getHtml = node => ({
    __html: node.childMarkdownRemark.html,
  })

  return (
    <Layout id="about">
      <SEO title="About Us" description={dato.heroBody} />

      <ImageHero
        fluid={data.hero.childImageSharp.fluid}
        alt="INW Product: Vanilla Bean Protein Powder"
      >
        <h1>{dato.heroHeadline}</h1>
        <div
          className="lede"
          dangerouslySetInnerHTML={getHtml(dato.heroBodyNode)}
        />
      </ImageHero>

      <section id="mission-bar">
        <div id="mission">
          <div className="content">
            <h3>Our Mission</h3>
            <div
              dangerouslySetInnerHTML={getHtml(dato.ourMissionNode)}
            />
          </div>
        </div>
        <div id="vision">
          <div className="content">
            <h3>Our Vision</h3>
            <div
              dangerouslySetInnerHTML={getHtml(dato.ourVisionNode)}
            />
          </div>
        </div>
      </section>
      <section id="esg">
        <div className="intro">
          <h2>{dato.commitmentHeadline}</h2>
          <div
            className="lede"
            dangerouslySetInnerHTML={getHtml(dato.commitmentBodyNode)}
          />
        </div>
        <div className="esg-items">
          {dato.commitmentEsg.map((item, i) => (
            <IconBlock data={item} key={i} />
          ))}
        </div>
      </section>
      <section id="history">
        <div className="intro">
          <h2>{dato.timelineHeadline}</h2>
          <div
            className="lede"
            dangerouslySetInnerHTML={getHtml(dato.timelineBodyNode)}
          />
        </div>
        <section id="timeline">
          {dato.timelineEntries.map(entry => (
            <div className="entry" key={entry.id}>
              <div className="image">
                <div className="entry-video-wrap">
                  <div className="video-wrap-inner">
                    <InlineVideo
                      className="entry-video"
                      url={entry.video.url}
                      width="100%"
                      height="0"
                    />
                  </div>
                  <div className="date">
                    <span>{entry.year}</span>
                  </div>
                </div>
              </div>
              <div className="details">
                <Link
                  className="logo"
                  to={linkShapes.networkSite(entry.site.siteName)}
                >
                  <INWLogo />
                  <div className="site-name">
                    <span>{entry.site.logoLine1}</span>
                    <span>{entry.site.logoLine2}</span>
                  </div>
                </Link>
                <div
                  className="description"
                  dangerouslySetInnerHTML={getHtml(
                    entry.descriptionNode
                  )}
                />
              </div>
            </div>
          ))}
        </section>
      </section>

      {/* <section id="our-people">
        <div className="intro">
          <h3>{dato.ourPeopleHeadline}</h3>
          <div
            className="lede"
            dangerouslySetInnerHTML={getHtml(dato.ourPeopleBodyNode)}
          />
        </div>
        <PeopleSlider />
      </section> */}

      {dato.ceoLetterHeadline && dato.ceoLetterBodyNode && (
        <section id="ceo-letter">
          <div className="letter">
            <h3>{dato.ceoLetterHeadline}</h3>
            <div
              className="letter-body"
              dangerouslySetInnerHTML={getHtml(dato.ceoLetterBodyNode)}
            />
            <div
              className="letter-signature"
              dangerouslySetInnerHTML={getHtml(
                dato.ceoLetterSignatureNode
              )}
            />
          </div>
          {dato.ceoPhoto && (
            <div className="image">
              <Img fluid={dato.ceoPhoto.fluid} />
            </div>
          )}
        </section>
      )}
    </Layout>
  )
}

export default AboutPage
